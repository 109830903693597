import React,{useState} from 'react';
import { Routes, Route  } from 'react-router-dom';
import { AuthContextProvider } from './context/AuthContext';
import ProfileContext from './context/ProfileContext';
import Layout from './pages/Layout';
import Signin from './pages/Signin';
import { Mobile } from './pages/Mobile';
import ErrorBoundary from './Components/ErrorBoundary';

function App() {
  const [profile, setProfile] = useState({ currentCompany: '', totalExperience: 0 });

  return (
    <ErrorBoundary>
      <AuthContextProvider>
        <ProfileContext.Provider value={{profile, setProfile}}>
          <Routes>
            <Route path='/' element={<Signin />} />
            <Route path="*" element={<Layout />} />
            <Route path='/mobile' element={<Mobile />} />
          </Routes>
        </ProfileContext.Provider>
     </AuthContextProvider>
    </ErrorBoundary>
  );
}

export default App;



// // src/App.js
// import React, { useEffect, useRef, useState } from 'react';
// import CodeMirror from '@uiw/react-codemirror';
// import Header from './Header';
// import './App.css';

// const App = () => {
//   const [question, setQuestion] = useState('');
//   const [example, setExample] = useState('');
//   const [code, setCode] = useState('')
//   const codeEditor = useRef(null);

//   useEffect(() => {
//     // Call the Fetch Function on every refresh
//     fetchQuestion();
//   }, []);
  
//   // Fetch Question Implementation
//   const fetchQuestion = async () => {
//     try {
//       const response = await fetch('http://localhost:3010/question', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({}),
//       });

//       const data = await response.json();
//       const { question, example } = data.result;
//       setQuestion(question);
//       setExample(example);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   // SubmitCode and Result API implmentations
//   const submitCode = async () => {
//     console.log("**********")
//     console.log(code)
    
//     try {
//       const response = await fetch('http://localhost:3010/api', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ question, code }),
//       });

//       const result = await response.text();
//       console.log(result);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   // Set the CodeMirror Value in value state
//   const onChange = React.useCallback((value, viewUpdate) => {
//     console.log('value:', value);
//     setCode(value)
//   }, []);

//   return (
//     <div className="App">
//       <Header />
//       <div id="question-view">
//         <p>
//           <strong>Question: {question}</strong>
//         </p>
//         <p>
//           <strong>Example:</strong> {example}
//         </p>
//       </div>
//       <br />
//       <label htmlFor="code">Code:</label>
//       <br />
//       <div id="code-editor" ref={codeEditor}></div>
//       <CodeMirror
//         value={code}
//         height="200px"
//         onChange={onChange}
//         onBeforeInput={onBeforeInput}
//       />
//       <br />
//       <br />
//       <button onClick={submitCode}>Submit</button>
//     </div>
//   );
// };

// export default App;
