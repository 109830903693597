import React, { Component } from 'react';
import * as Sentry from '@sentry/react';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        Sentry.captureException(error);
        Sentry.captureMessage("Error in landing page", {
            level: 'error',
            extra: info,
        });
    }

    render() {
        if (this.state.hasError) {
            return <h1></h1>;
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
